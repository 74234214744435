<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    objectFit?: "fill" | "contain" | "cover" | "none" | "scale-down";
    src?: string;
    aspectRatio?: number | string;
    alt?: string;
    sizes?: string;
    preload?: boolean;
    width?: string;
  }>(),
  {
    objectFit: "cover",
    src: "",
    aspectRatio: undefined,
    alt: "",
    sizes: "xs:100vw sm:100vw md:100vw lg:1280px xl:1312px xxl:1952px",
  },
);

const isSvg = computed(() => props.src.endsWith(".svg"));
</script>

<template>
  <img
    v-if="isSvg"
    :src="src"
    :alt="alt"
    v-bind="$attrs"
    :loading="preload ? 'eager' : 'lazy'"
    style="max-width: 100%"
    :style="{ objectFit: props.objectFit }"
  />

  <v-responsive
    v-else
    :aspect-ratio="aspectRatio"
    class="v-img"
    v-bind="$attrs"
    role="img"
  >
    <nuxt-picture
      :src="src"
      class="v-img__picture d-block"
      :img-attrs="{
        class: 'v-img__img',
        alt: alt,
        style: { 'object-fit': objectFit },
      }"
      :width="width"
      :aria-label="alt"
      :loading="preload ? 'eager' : 'lazy'"
      :preload="preload"
      :sizes="sizes"
    >
    </nuxt-picture>

    <slot></slot>
  </v-responsive>
</template>
